import React from 'react'
import { IRS_CORNER } from '../../components/constant'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
const DeputationOpportunites = () => {
  return (
    <div>
      <Layout>
        <SEO title=" Deputation Opportunites" />
        <PageStructure
          title=" Deputation Opportunites"
          desc="TESTIG"
          mode={IRS_CORNER}
        />
      </Layout>
    </div>
  )
}

export default DeputationOpportunites
